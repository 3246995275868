<template>
    <div>
        <Header :id="id"></Header>
        <div class="main">
            <div class="info">
              <div class="title">{{total}} products found for "{{keywords}}"</div> 
              <div class="pro1">
                <div class="duct1" v-for="(item,index) in products.lists" :key="index">
                    <div class="proimg">
                        <router-link :to="{ name: 'Productdetail', params: { id: item.companyId, productId: item.productId } }">
                        <img :src="item.thumb" alt="">
                        </router-link>
                    </div>
                    <div class="protxt">
                        {{item.productName}}
                    </div>
                    <div class="protxt2">
                            <span>{{item.minPrice}}</span>
                    </div>
                    <div class="protxt3">{{ $t('Min.Order') }}: {{item.minOrder}}</div>
                    <div class="rtxt6">
                        <div class="rtxt5">Contact Now</div>
                        <div class="rheart"> <a-icon type="heart" /></div>
                    </div>
                </div>    
              </div>
            </div>
            <!-- 分页 -->
            <div class="page">
                <a-pagination
                    showQuickJumper
                    :defaultCurrent="pagination.defaultCurrent"
                    :defaultPageSize="pagination.defaultPageSize"
                    :total="pagination.total"
                    @change="onChange"
                    @showSizeChange="onShowSizeChange"
                />
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "../components/Footer";

export default {
  inject: ['reload'], // 注入依赖
  name: "ExbitProductSearch",
  components: {
    Footer,
    Header
  },
  data() {
    return {
      keywords: this.$route.params.keywords,
      page: 1,
      id: this.$route.params.id,
      products: [],
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        defaultCurrent: 1, // 默认当前页数
        defaultPageSize: 8, // 默认当前页显示数据的大小
        total: 0 // 总数，必须先有
      },
      total: 0
    }
  },
  mounted(){
    this.getProducts()
  },
  methods: {
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    onChange(pageNumber) {
        console.log('Page: ', pageNumber);
        this.page = pageNumber
        console.log(this.page)
        // 查数据
    },
     //分页
    onShowSizeChange (current, pageSize) {
      this.pagination.defaultCurrent = 1
      this.pagination.defaultPageSize = pageSize
      this.getProducts() // 显示列表的接口名称
    },
    onChange(current, size) {
      this.pagination.defaultCurrent = current
      this.pagination.defaultPageSize = size
      // 查数据
      this.getProducts()
    },
    // 展商展品页面
    getProducts () {
       if ( this.keywords == '' ) {
         this.$layer.alert("搜索内容不能为空");
         return
       }
       const params = {
            page: this.pagination.defaultCurrent,
            pagesize: this.pagination.defaultPageSize,
            keywords: this.keywords,
            exhibitionId: this.id
       }
       this.$common.fetchList('/exhibition/product/lists', Object.assign({}, params)).then(data => {
        if (data.code === 200) {
          const result = data.data
          const pagination = { ...this.pagination }
          pagination.total = result.totalInfo.total_items
          this.products = result
          this.pagination = pagination
          this.total = data.data.totalInfo.total_items
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    },
  },
  watch: {
    '$route.params.keywords' (newVal, oldVal) {
      this.reload()
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/spproductsearch.less';
</style>